<template>
  <div class="w-full border-t-2 border-black flex mt-20 lg:mt-0">
    <div
      class="
        hidden
        text-9xl
        h-full
        w-40
        text-vertical
        lg:flex
        justify-between
        items-center
        border-r-2 border-black
more-bottom
      "
    >
      <div class="mt-5 scale--1 more-bottom">More</div>
    </div>
    <div class="text-xl lg:text-3xl lg:text-6xl w-full">
      <div
        class="
          w-full
          border-b-2 border-black
          bg-spring-wood
          text-black
          transition-all
          hover:text-spring-wood 
          hover:bg-black
          more-right
          delay-1
        "
      >
        <a
          class="block w-full h-full p-8"
          href="https://github.com/RSelwa/"
          target="_blank"
        >
          Github
        </a>
      </div>
      <div
        class="
          w-full
          border-b-2 border-black
          bg-spring-wood
          text-black
          transition-all
          hover:text-spring-wood 
          hover:bg-black
          more-right
          delay-2
        "
      >
        <a
          class="block w-full h-full p-8"
          href="https://www.linkedin.com/in/raphael-selwa-prevost"
          target="_blank"
        >
          Linkedin
        </a>
      </div>
      <div
        class="
          w-full
          border-b-2 border-black
          bg-spring-wood
          text-black
          transition-all
          hover:text-spring-wood 
          hover:bg-black
          more-right
          delay-3
        "
      >
        <a
          class="block w-full h-full p-8"
           href="./curriculumVitae.pdf"
          target="_blank"
        >
          Curriculum vitae
        </a>
      </div>
      <div
        class="
          w-full
          border-b-2 border-black
          bg-spring-wood
          text-black
          transition-all
          hover:text-spring-wood 
          hover:bg-black
          more-right
          delay-4
        "
      >
        <a
          class="block w-full h-full p-8"
          href="https://www.instagram.com/raphaelselwa/"
          target="_blank"
        >
          Instagram
        </a>
      </div>
      <div
        class="
          w-full
          border-b-2 border-black
          bg-spring-wood
          text-black
          transition-all
          hover:text-spring-wood 
          hover:bg-black
          more-right
          delay-5
         
        "
        @click="mail"
      ><div
          class="block w-full h-full p-8  flex justify-between"
          
          target="_blank"
        >
        
        <div>
          Mail
        </div>
        <div class="text-spring-wood greeting 	" id="mail" :class="{'opacity-0': this.email}">
          selwa.raphael@gmail.com
        </div>
        </div>
        
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      email:false
    }
  },
  methods:{
    mail(){
      let elem =document.getElementById('mail')
      let str = elem.innerHTML
      var el = document.createElement('textarea');
   // Set value (string to be copied)
   el.value = str;
   // Set non-editable to avoid focus and move outside of view
   el.setAttribute('readonly', '');
   el.style = {position: 'absolute', left: '-9999px'};
   document.body.appendChild(el);
   // Select text inside element
   el.select();
   // Copy text to clipboard
   document.execCommand('copy');
   // Remove temporary element
   document.body.removeChild(el);

this.email= true


setTimeout(() => {
  this.email = false
  elem.innerHTML = "copied"
}, 300);
setTimeout(() => {
  this.email = true
}, 2700);

setTimeout(() => {
  elem.innerHTML = str
  this.email = false
}, 3000);
    },
    pdf(){
      var pdf = '/src/assets/iban.pdf'
      window.open(pdf)
    }
  }
}
</script>
