<template>
  <div class="text-4xl lg:text-9xl ml-32 my-24 lg:my-12 landing-left">
    Projects
  </div>
  <div
    class="relative mx-12 inline-block projects-gallery lg:flex lg:overflow-x-hidden lg:relative lg:bottom-0 lg:mt-24 landing-right"
  >
    <div
      class="project mb-12 h-56 flex items-start lg:mr-12 lg:h-auto lg:w-100 lg:flex-col-reverse lg:relative"
      v-for="(project, index) in projects"
      :key="project.id"
      @click="displayProject(project)"
    >
      <div class="h-full lg:w-96 lg:pt-40 lg:h-auto">
        <img
          class="object-contain h-full lg:w-full filter grayscale"
          v-bind:src="project.image"
          v-bind:alt="project.titre"
        />
      </div>
      <div
        class="project-text transition-all text-2xl ml-4 mt-12 md:mt-0 lg:text-xl lg:absolute lg:top-24 z-30"
      >
        <div class="index-project">
          {{ this.numberProjects(index) }}
        </div>
        <div
          class="project-description hidden lg:block lg:text-2xl lg:invisible lg:z-30"
        >
          <div class="lg:my-2">
            {{ project.titre }}
          </div>
          <div class="lg:text-xl lg:my-2">
            {{ project.delivered }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="end"
    class="h-full w-full bg-spring-wood absolute top-20 z-40 p-12 lg:p-36"
    :class="{ 'project-translateY': project, 'project-translateY-end': !end }"
  >
    <div @click="disableProject" class="pb-4">
      <i class="bi bi-arrow-left-short text-4xl"></i>
    </div>
    <div class="flex">
      <div class="font-bold hidden lg:block">Projects</div>
      <div class="lg:ml-6">{{ this.project.lineOfBusiness }}</div>
    </div>
    <div class="text-7xl lg:text-9xl my-8">
      <a
        :href="this.project.link"
        target="_blank"
        rel="noopener noreferrer"
        style="text-decoration: underline"
      >
        {{ this.project.titre }}
      </a>
    </div>
    <div class="flex flex-col lg:flex-row lg:justify-between mt-12 lg:mt-0">
      <div class="flex flex-row lg:flex-col">
        <div class="font-bold mb-8 w-24">year</div>
        <div class="">{{ this.project.year }}</div>
      </div>
      <div class="flex flex-row lg:flex-col">
        <div class="font-bold mb-8 w-24">Client</div>
        <div class="">{{ this.project.client }}</div>
      </div>
      <div class="flex flex-row lg:flex-col lg:w-3/6">
        <div class="font-bold mb-8 w-24">Delivered</div>
        <div class="w-5/6 lg:w-auto">{{ this.project.content }}</div>
      </div>
    </div>
  </div>
  <div
    v-bind:style="{ 'animation-delay': '1.25s' }"
    class="text-xl fixed bottom-12 right-12 hidden lg:block landing-right opacity-0"
  >
    2021
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Projects",
  components: {},
  data() {
    return {
      projects: [],
      project: {},
      end: false,
    };
  },
  methods: {
    displayProject(prj) {
      console.log("object");
      this.project = prj;
      this.end = true;
    },
    disableProject() {
      this.end = false;
      setTimeout(() => {
        this.project = null;
      }, 500);
    },
    numberProjects(nb) {
      let formattedNumber = (nb + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      return formattedNumber;
    },
  },
  async mounted() {
    const response = await axios.get(
      "https://portfolioController.r-selwa.space/api/projects/",
      // process.env.VUE_APP_PATH_API + "/api/projects/",
      {
        params: {
          status: "true",
        },
      }
    );

    this.projects = response.data.filter((project) => project.status == true);
    // // this.project = response.data[0]
    // console.log(this.projects);
    // console.log(this.project);

    const scrollContainer = document.querySelector(".projects-gallery");

    window.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
    });
  },
};
</script>
