<template>
  <div class="about mt-20 lg:mt-0 w-full">
    <div class="border-t-2 border-b-2 border-black pl-6 lg:hidden">
      <div class="text-4xl landing-left">About me</div>
    </div>
    <div
      class="landing-left hidden lg:block text-4xl lg:text-9xl border-t-2 border-b-2 border-black pl-6 lg:border-none lg:absolute lg:left-1/4 lg:top-1/4 lg:z-20"
    >
      About me
    </div>
    <img
      v-bind:src="this.project.image"
      alt="te"
      class="about-img rounded filter grayscale brightness-200 mix-blend-multiply m-auto bg-red-200 mt-12 w-4/6 h-96 object-cover lg:absolute lg:z-10 lg:w-2/6 lg:left-1/6 lg:object-cover lg:h-3/4 lg:tr"
    />
    <div
      class="landing-right w-4/6 m-auto my-12 lg:absolute lg:z-20 lg:left-1/3 lg:top-4/10 lg:p-12 lg:rounded lg:w-1/2 lg:bg-spring-wood lg:bg-opacity-75 lg:shadow"
    >
      {{ project.content }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      project: {},
      projects: [],
    };
  },
  //  name: "Projects",
  async mounted() {
    const response = await axios.get(
      "https://portfolioController.r-selwa.space/api/projects/",
      // process.env.VUE_APP_PATH_API + "/api/projects/",
      {
        params: {
          titre: "about",
        },
      }
    );

    this.project = response.data.find((project) => project.titre == "about");
  },
};
</script>
